import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },  
  {
    path: '/',
    name: 'index',
    component: () => import("@/views/Index.vue"),
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import("@/views/Introduce.vue"),
  },
  {
    path: '/ecology',
    name: 'ecology',
    component: () => import("@/views/ecology.vue"),
  },
  {
    path: '/development',
    name: 'development',
    component: () => import("@/views/Development.vue"),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import("@/views/Team.vue"),
  },
  {
    path: '/pdfReader/:filename',
    name: 'pdfReader',
    component: () => import("@/views/PdfReader.vue"),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import("@/views/Terms.vue"),
  },
  {
    path: '/header',
    name: 'header',
    component: () => import("@/components/Header.vue"),
  },
  {
    path: '/footer',
    name: 'footer',
    component: () => import("@/components/Footer.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
